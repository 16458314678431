import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`
  html {
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    font-size: var(--default-font-size);
    line-height: 1.5;
    color: var(--black);
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: calc(var(--spacer) * 2);
    margin-bottom: calc(var(--spacer) * 1.5);
    font-weight: 500;
    line-height: 1.25;
    text-transform: uppercase;
  }

  h1 {
    font-size: 10vw;
    margin-top: calc(var(--spacer) * 2);
    margin-bottom: calc(var(--spacer) * 2);

    @media (min-width: 300px) {
      font-size: calc(var(--default-font-size) * 2);
    }
  }

  h2 {
    font-size: 8vw;

    @media (min-width: 300px) {
      font-size: calc(var(--default-font-size) * 1.75);
    }
  }

  h3 {
    font-size: 7vw;

    @media (min-width: 300px) {
      font-size: calc(var(--default-font-size) * 1.5);
    }
  }

  h4 {
    font-size: 6vw;
    
    @media (min-width: 300px) {
      font-size: calc(var(--default-font-size) * 1.25);
    }
  }

  h5 {
    font-size: calc(var(--default-font-size) * 1);
  }

  h6 {
    font-size: var(--default-font-size);
  }

  a {
    color: var(--color-secondary);
    text-decoration: underline;
    text-decoration-color: var(--color-secondary);

    &:hover, &:active {
      text-decoration-color: var(--color-primary);
    }
  }

  ol, ul, p, pre, table, caption, hr {
    margin: 0 0 var(--spacer);
  }

  ul, ol {
    padding: 0 0 0 1.5em;
  }
`;

export default Typography;
