import React from 'react';
import logo from '../assets/images/logo-header.svg';

const HeaderLogo = () => (
  <img
    src={logo}
    alt="Irmaisterin keittiössä"
    width="237"
    height="90"
  />
);

export default HeaderLogo;
