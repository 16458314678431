import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export default function SEO({
  children, location, description, title, imageUrl, type,
}) {
  const { siteSettings } = useStaticQuery(graphql`
    query {
      siteSettings: sanitySiteSettings(_id: {eq: "singleton-siteSettings"}) {
        title
        url
        description
        author {
          name
          websiteUrl
        }
        logo {
          asset {
            url
          }
        }
      }
    }
  `);

  const canonicalUrl = [siteSettings.url, location.pathname].join('');
  const desc = description || siteSettings.description;
  return (
    <Helmet defaultTitle={siteSettings.title} titleTemplate={`%s | ${siteSettings.title}`}>
      <html lang="fi" />
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta name="description" content={desc} />
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:locale" content="fi_FI" />
      <meta property="og:type" content={type || 'website'} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={imageUrl || siteSettings.logo.asset.url} />
      <meta property="og:title" content={title || siteSettings.title} key="ogtitle" />
      <meta property="og:site_name" content={siteSettings.title} key="ogsitename" />
      <meta property="og:description" content={desc} key="ogdesc" />
      {children}
    </Helmet>
  );
}
