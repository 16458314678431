import React from 'react';
import ReactDOM from "react-dom/client";
import Layout from './src/components/Layout';

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

// https://github.com/gatsbyjs/gatsby/discussions/36232
export function replaceHydrateFunction() {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
