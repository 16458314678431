import React from 'react';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Header from './Header';
import Footer from './Footer';
import SEO from './SEO';

const Layout = ({ children, location }) => (
  <>
    <GlobalStyles />
    <Typography />
    <SEO location={location} />
    <Header />
    {children}
    <Footer />
  </>
);

export default Layout;
