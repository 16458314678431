exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-reseptit-jsx": () => import("./../../../src/pages/reseptit.jsx" /* webpackChunkName: "component---src-pages-reseptit-jsx" */),
  "component---src-pages-tietoa-jsx": () => import("./../../../src/pages/tietoa.jsx" /* webpackChunkName: "component---src-pages-tietoa-jsx" */),
  "component---src-pages-tietosuojaseloste-jsx": () => import("./../../../src/pages/tietosuojaseloste.jsx" /* webpackChunkName: "component---src-pages-tietosuojaseloste-jsx" */),
  "component---src-templates-recipe-jsx": () => import("./../../../src/templates/Recipe.jsx" /* webpackChunkName: "component---src-templates-recipe-jsx" */)
}

