import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import paths from '../../paths.mjs';

const FooterStyles = styled.footer`
  margin-top: auto;
  text-align: center;
  margin: var(--spacer);
`;

const ParagraphStyles = styled.p`
  margin: 0.5rem;
`;

const Footer = () => (
  <FooterStyles>
    <ParagraphStyles>
      Irmaisterin Keittiössä &copy;
      {' '}
      {new Date().getFullYear()}
    </ParagraphStyles>
    <ParagraphStyles>
      <Link to={paths.privacyPolicy.root()}>Tietosuojaseloste</Link>
    </ParagraphStyles>
  </FooterStyles>
);

export default Footer;
