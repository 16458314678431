import React, { useState, useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import paths from '../../paths.mjs';
import HeaderLogo from './HeaderLogo';

const headerHeight = 110;

const HeaderStyles = styled.header`
  --z-index-header: 10;

  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  position: fixed;
  top: ${({ hide, height }) => (hide ? `-${height}px` : 0)};
  left: 0;
  z-index: var(--z-index-header);
  background-color: var(--color-primary);
  transition: top .3s ease;

  .logo-container {
    z-index: var(--z-index-header);
    margin: 10px 0 10px 10px;
    height: 90px;
    width: calc(100% - 110px);

    a {
      height: 100%;
    }
  }

  .logo {
    max-width: 237px;
    width: 100%;
  }
`;

const NavigationContainerStyles = styled.div`
  --nav-duration: 0.25s;
  --nav-timing: cubic-bezier(.24,1.22,.5,1.04);
  --z-index-below: -1;

  margin: 30px;

  nav {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    transform: translateY(-100%);
    transition: var(--nav-duration);
    z-index: var(--z-index-below);
    
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-primary);
      transform-origin: 0 50%;
      z-index: var(--z-index-below);
    }

    ul {
      list-style: none;
      margin: 0 auto;
      padding: 0;
    }

    li {
      transform: translateY(-100vh);
    }

    a {
      color: var(--black);
      text-decoration: none;
      line-height: 1;
      user-select: none;

      &:hover, &:focus, &:active {
        text-decoration: none;
        h2 {
          background: rgba(0, 0, 0, 1);
        }
      }
    }

    .nav-link-active {
      h2 {
        background: rgba(0, 0, 0, 1);
      }
    }

    h2 {
      font-size: 10vmin;
      text-transform: uppercase;
      color: var(--color-primary);
      background: rgba(0, 0, 0, 0.8);
      padding: var(--spacer) calc(var(--spacer) * 2);
      transition: all .5s;
      text-align: center;
      margin: var(--spacer);
    }
  }

  // visually hidden
  input {
    position: absolute; 
    overflow: hidden; 
    clip: rect(0 0 0 0); 
    height: 1px; width: 1px; 
    margin: -1px; padding: 0; border: 0;

    :checked {
      ~ label .icon-container {    
      .icon-group {
          transform: translateX(200%);
        }
      }

      ~ nav {
        transition-duration: 0s;
        transform: translateY(0);
        
        &:after {
          animation: navigation-background var(--nav-duration) var(--nav-timing) forwards;
        }
        
        li {
          animation: link-appear var(--nav-duration) var(--nav-timing) forwards;
          &:nth-of-type(4) {
            animation-delay: calc(var(--nav-duration) * 0.25);
          }
          &:nth-of-type(3) {
            animation-delay: calc(var(--nav-duration) * 0.5);
          }
          &:nth-of-type(2) {
            animation-delay: calc(var(--nav-duration) * 0.75);
          }
          &:nth-of-type(1) {
            animation-delay: var(--nav-duration);
          }
        }

        @keyframes navigation-background {
          from { transform: translateY(-100%) }
          to { transform: translateY(0) }
        }

        @keyframes link-appear {
          from { transform: translateY(-100vh); }
          to { transform: translateY(0); }
        }
      }
    }
  }

  label {
    cursor: pointer;

    span {
      position: fixed;
      height: 1px; 
      width: 1px;
      overflow: hidden;
      clip: rect(1px, 1px, 1px, 1px);
      white-space: nowrap;
    }

    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      stroke-width: 6;
    }

    .icon-group {
      transform: translateX(0);
      transition: transform var(--nav-duration) var(--nav-timing);
    }

    .icon--open {
      stroke: var(--black);
    }

    .icon--close {
      stroke: var(--black);
      transform: translateX(-200%);
    }
  }
`;

const Header = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          navItems {
            name
            path
          }
        }
      }
    }
  `);
  const [checked, setChecked] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);

  const toggleHideHeader = () => {
    if (checked) return;
    const { scrollY } = window;
    if (scrollY > prevScrollY && scrollY > headerHeight) {
      setHideHeader(true);
    } else {
      setHideHeader(false);
    }
    setPrevScrollY(scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleHideHeader, true);
    return () => {
      window.removeEventListener('scroll', toggleHideHeader, true);
    };
  });

  const handleCheckboxChange = (event) => setChecked(event.target.checked);
  const handleLinkClick = () => setChecked(false);

  return (
    <HeaderStyles height={headerHeight} hide={hideHeader}>
      <div className="logo-container">
        <Link to={paths.home.root()}>
          <HeaderLogo />
        </Link>
      </div>
      <NavigationContainerStyles>
        <input id="main-navigation-toggle" type="checkbox" onChange={handleCheckboxChange} checked={checked} />
        <label htmlFor="main-navigation-toggle">
          <span>Navigation toggle</span>
          <svg className="icon-container" viewBox="0 0 60 30">
            <g className="icon-group">
              <g className="icon--open">
                <path d="M 6 0 L 54 0" />
                <path d="M 6 15 L 54 15" />
                <path d="M 6 30 L 54 30" />
              </g>
              <g className="icon--close">
                <path d="M 15 0 L 45 30" />
                <path d="M 15 30 L 45 0" />
              </g>
            </g>
          </svg>
        </label>
        <nav>
          <ul>
            {site.siteMetadata.navItems.map((item) => (
              <li key={item.name}>
                <Link to={item.path} onClick={handleLinkClick} activeClassName="nav-link-active">
                  <h2>{ item.name }</h2>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </NavigationContainerStyles>
    </HeaderStyles>
  );
};

export default Header;
