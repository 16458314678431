const paths = {
  home: {
    root: () => '/',
  },
  recipes: {
    root: () => '/reseptit/',
    old_category: (categorySlug) => `/reseptit/${categorySlug}/`,
    category: (categorySlug) => `/reseptit/?categories=${categorySlug}`,
    recipe: (recipeSlug) => `/reseptit/${recipeSlug}/`,
  },
  blogs: {
    root: () => '/blogi/',
    blog: (blogSlug) => `/blogi/${blogSlug}/`,
  },
  info: {
    root: () => '/tietoa/',
  },
  privacyPolicy: {
    root: () => '/tietosuojaseloste/',
  },
};

export default paths;
